class LifeVineSession {
    private lv: LifeVine;
    private token: string|boolean;

    private loginCallbacks: Function[] = [];
    private logoutCallbacks: Function[] = [];
    private timeoutCallbacks: Function[] = [];

    constructor(lv: LifeVine) {
        this.lv = lv;

        this.setToken();
    }

    public check(loggedIn: Function, loggedOut: Function) {
        if (this.token === false) {
            loggedOut();
            return;
        }

        this.lv.ajax('GET', '/session')
            .done((data, status, xhr) => {
                if (data.success) {
                    LifeVine.runCallbacks(this.loginCallbacks, xhr, data);
                } else {
                    localStorage.removeItem('csrfToken');
                    this.token = false;
                    loggedOut();
                }

            })
            .fail(xhr => {
                loggedOut();
            });
    }

    public getToken() {
        return this.token;
    }

    public isLoggedIn() {
        return !!this.token;
    }

    public login(username: String, password: String) {
        let deferred = jQuery.Deferred();
        let data = {
            username: username,
            password: password
        };

        this.lv.ajax('POST', '/session', data)
            .done((data, status, xhr) => {
                if (data.success) {
                    this.setToken(data.csrfToken);
                    LifeVine.runCallbacks(this.loginCallbacks, xhr, data);
                    deferred.resolve(data);
                }
                deferred.reject(xhr);
            })
            .fail(xhr => {
                deferred.reject(xhr);
            });

        return deferred.promise();
    }

    public forgot(email: String) {
        return this.lv.ajax('POST', '/forgot-password', {email: email});
    }

    public setPassword(token: String, password: String) {
        return this.lv.ajax('POST', '/set-password', {token: token, password: password});
    }

    public logout() {
        this.lv.ajax('DELETE', '/session')
            .done((data, status, xhr) => {
                this.deleteToken();
                LifeVine.runCallbacks(this.logoutCallbacks, xhr);
            });
    }

    public getTimeoutStatusCode() {
        return (xhr) => this.timeout(xhr);
    }

    public onLogin(callback: Function) {
        this.loginCallbacks.push(callback);
    }

    public onLogout(callback) {
        this.logoutCallbacks.push(callback);
    }

    public onTimeout(callback) {
        this.timeoutCallbacks.push(callback);
    }

    private timeout(xhr) {
        if (xhr.responseJSON.hasOwnProperty('error')) {
            if (xhr.responseJSON.error === 'timeout') {
                this.deleteToken();
                LifeVine.runCallbacks(this.timeoutCallbacks, xhr);
            }
        }
    }

    private setToken(token: string = '') {
        if (token.length > 0) {
            this.token = token;
            localStorage.setItem('csrfToken', token);
        } else {
            let token = localStorage.getItem('csrfToken');
            this.token = token ? token : false;
        }
    }

    private deleteToken() {
        localStorage.removeItem('csrfToken');
        this.token = false;
    }
}

interface LifeVineSessionUser {
    first_name: string;
    last_name: string;
    user_type: string;
    success: boolean;
}